import React, { useEffect, useState, useRef } from 'react'
import logo from '../../../assets/images/logo.png'
import $, { isEmptyObject } from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import { Get_Cart_Api, Login_User_Api, Get_Login_User_Api, User_Verify_Api, Log_Out_Api, Register_User_Api, Change_Delivery_Type_Api, Add_Money_Wallet_Api, Get_AllCityOutlet_Api, Update_PickUp_Outlet, Outlet_Search_Api, Get_Nearest_Outlet } from '../../../api';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = ({ fileName, nearestOutletParams, setNearestOutletParams, setSearchTextParams, cartCount }) => {
    const navigate = useNavigate();
    const loginFormRef = useRef(null);
    const otpFormRef = useRef(null);
    const signUpFormRef = useRef(null);
    const addWalletRef = useRef(null);
    const pickUpLocationFormref = useRef(null);
    const formRef = useRef(null);
    const [localStorageLocation, setlocalStorageLocation] = useState(JSON.parse(localStorage.getItem('location')));
    const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
    const [nearestOutlet, setNearestOutlet] = useState(localStorageLocation || nearestOutletParams);
    const [userData, setUserData] = useState({});
    const [LocationSuggestionData, setLocationSuggestionData] = useState([]);
    const [city, setCity] = useState([]);
    const [outlets, setOutlets] = useState({ selected: [], all: [] });
    const [userName, setUserName] = useState(null);
    const [Mobile_Number, setMobile_Number] = useState('');
    const [area, setArea] = useState({});
    const [userNumber, setuserNumber] = useState(null);
    const [CartCount, setCartCount] = useState(0);
    const [DeliveryType, setDeliveryType] = useState(localStorage.getItem('orderType') || "delivery");
    const [Recent_location, setRecent_location] = useState(JSON.parse(localStorage.getItem('recent_location')) || []);
    const token = localStorage.getItem('authToken');
    useEffect(() => {
        let localStorageLocationsetdata = {
            city: nearestOutlet.city,
            id: nearestOutlet.id,
            name: nearestOutlet.masterName ? nearestOutlet.masterName : nearestOutlet.name,
            outletType: nearestOutlet.outletType,
            masterName: nearestOutlet.masterName,
        }
        localStorage.setItem('location', JSON.stringify(localStorageLocationsetdata));
    }, [nearestOutlet]);
    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setUserLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }
    }, []);
    useEffect(() => {
        if (userLocation.latitude != null && userLocation.longitude != null) {
            axios({
                method: 'post',
                url: Get_Nearest_Outlet,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: userLocation,
            }).then(response => {
                let nearestLocation = response.data.data.nearestLocation;
                if (localStorage.getItem('location') == null || localStorage.getItem('location') == undefined) {
                    setNearestOutlet(nearestLocation);
                    if (typeof setNearestOutletParams != typeof undefined) {
                        setNearestOutletParams(nearestLocation);
                    }
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }, [userLocation])
    const CartCountFunction = () => {
        if (token != null) {
            var config = {
                method: 'get',
                url: Get_Cart_Api,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            axios(config)
                .then((response) => {
                    setCartCount(response.data.data.cart_data.length || 0);
                })
                .catch((error) => {
                    console.log(error);
                });
            setCartCount(0);
        }
    }
    const LogOutFunction = () => {
        var config = {
            method: 'get',
            url: Log_Out_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                toast.success(response.data.msg);
                localStorage.clear();
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            })
            .catch((error) => {
                console.log(error);
            });
        setCartCount(0);
    }
    useEffect(() => {
        $(loginFormRef.current).validate({
            rules: {
                mobile_number: {
                    required: true,
                    minlength: 10,
                    maxlength: 10,
                },
            },
            messages: {
                mobile_number: {
                    required: 'Please enter a mobile number',
                    minlength: 'Please enter valid mobile number',
                    maxlength: 'Please enter valid mobile number',
                },
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = $(form).serialize();
                var config = {
                    method: 'post',
                    url: Login_User_Api,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    data: n_form,
                };
                axios(config)
                    .then(async (response) => {
                        if (response.data.error == false) {
                            window.$('#loginModal').offcanvas('hide');
                            window.$('#otpModal').offcanvas('show');
                            setuserNumber(response.data.user.mobile_number);
                            toast.success(response.data.msg);
                            localStorage.setItem('otpToken', response.data.token);
                            const apiUrl = `https://enterprise.smsgupshup.com/GatewayAPI/rest?method=SendMessage&send_to=${response.data.user.mobile_number}&msg=Your%20OTP%20for%20Wok%20On%20Fire%20Login%20is%20${response.data.user.otp}.%20OTP%20is%20confidential%3B%20please%20enter%20it%20yourself%20%26%20do%20not%20disclose%20it%20to%20anyone.&msg_type=TEXT&userid=2000232741&auth_scheme=plain&password=Anjusateesh@1906&v=1.1&format=text`;
                            // Send the SMS using Axios
                            await axios.get(apiUrl)
                                .then(response => {
                                    console.log('SMS sent successfully:', response.data);
                                })
                                .catch(error => {
                                    console.error('Error sending SMS:', error);
                                });
                        } else {
                            toast.error(response.data.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
        if (token) {
            CartCountFunction();
        }
    }, [])
    useEffect(() => {
        $.validator.addMethod("emailValidate", function (value, element) {
            return /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
        }, "Email Address is invalid: Please enter a valid email address.");
        $(signUpFormRef.current).validate({
            rules: {
                first_name: {
                    required: true,
                },
                mobile_number: {
                    required: true,
                    minlength: 10,
                    maxlength: 10,
                },
                email: {
                    required: true,
                    emailValidate: true,
                }
            },
            messages: {
                first_name: {
                    required: 'Please enter a Name',
                },
                mobile_number: {
                    required: 'Please enter a mobile number',
                    minlength: 'Please enter 10 digit mobile number',
                    maxlength: 'Please enter 10 digit mobile number',
                },
                email: {
                    required: 'Please enter Email Address',
                }
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = $(form).serialize();
                var config = {
                    method: 'post',
                    url: Register_User_Api,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        if (response.data.error == false) {
                            toast.success(response.data.msg);
                            window.$('#signupModal').offcanvas('hide');
                            window.$('#loginModal').offcanvas('show');
                        } else {
                            toast.error(response.data.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
    }, [])
    useEffect(() => {
        if (token != null) {
            var config = {
                method: 'get',
                url: Get_Login_User_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            };
            axios(config)
                .then((response) => {
                    if (response.data.error == false) {
                        setUserData(response.data.data);
                        setDeliveryType(response.data.data.delivery_type);
                        localStorage.setItem("orderType", response.data.data.delivery_type)
                        setuserNumber(response.data.data.mobile_number);
                        if (response.data.data.first_name != undefined && response.data.data.last_name != undefined) {
                            setUserName(response.data.data.first_name + ' ' + response.data.data.last_name)
                        } else if (response.data.data.first_name != undefined) {
                            setUserName(response.data.data.first_name)
                        } else if (response.data.data.last_name != undefined) {
                            setUserName(response.data.data.last_name)
                        } else {
                            setUserName('User')
                        }
                        if (response.data.data.pickupOutletStore[0]) {
                            setArea({
                                _id: response.data.data.pickupOutletStore._id,
                                name: response.data.data.pickupOutletStore.name,
                                city: response.data.data.pickupOutletStore.city,
                                outletType: response.data.data.pickupOutletStore.outletType,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);
    useEffect(() => {
        if (token != null && typeof nearestOutlet != typeof undefined) {
            var config = {
                method: 'post',
                url: Update_PickUp_Outlet,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    area: nearestOutlet.id
                },
            };
            axios(config)
                .then((response) => {
                    setUserData(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token, nearestOutlet]);
    $(otpFormRef.current).validate({
        rules: {
        },
        messages: {
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            let OTP_DATA = document.getElementsByClassName('otp-get-data');
            let Send_Input_Otp = '';
            let check_Null = false;
            for (const key of OTP_DATA) {
                if (key.value) {
                    Send_Input_Otp += key.value;
                } else {
                    check_Null = true;
                }
            }
            if (check_Null == false) {
                var config = {
                    method: 'post',
                    url: User_Verify_Api,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${localStorage.getItem('otpToken')}`
                    },
                    data: { otp: Send_Input_Otp },
                };
                axios(config)
                    .then((response) => {
                        if (response.data.error == false) {
                            toast.success(response.data.msg);
                            localStorage.removeItem('otpToken');
                            localStorage.setItem('authToken', response.data.token);
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000)
                        } else {
                            toast.error(response.data.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                toast.error("Please enter 6 digit otp");
            }
        }
    });
    $(addWalletRef.current).validate({
        rules: {
            amount: {
                required: true
            }
        },
        messages: {
            amount: {
                required: 'please enter amount'
            }
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            // if (check_Null == false) {
            var config = {
                method: 'post',
                url: Add_Money_Wallet_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.encRequest) {
                        // $('#encRequest').val(response.data.encRequest)
                        // $('#access_code').val(response.data.accessCode)
                        $('#encRequest').val(response.data.encRequest)
                        $('#access_code').val(response.data.accessCode)
                        $(formRef.current).submit();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            // } else {
            //     toast.error("Please enter 6 digit otp");
            // }
        }
    });
    $(pickUpLocationFormref.current).validate({
        rules: {
            pickUp: {
                required: true
            },
            city: {
                required: true
            },
            area: {
                required: true
            }
        },
        messages: {
            pickUp: {
                required: 'please select delhivery type'
            },
            city: {
                required: 'please select city'
            },
            area: {
                required: 'please select area'
            }
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            if (token != null) {
                let n_form = $(form).serialize();
                let n_form_new = $(form).serializeArray();
                localStorage.setItem('pickupDinein', JSON.stringify(n_form));
                localStorage.setItem('location', JSON.stringify({
                    city: n_form_new[0].value,
                    id: n_form_new[1].value,
                    name: n_form_new[2].value,
                    outletType: n_form_new[3].value,
                    masterName: n_form_new[4].value,
                }));
                n_form += `&pickUp=1`;
                var config = {
                    method: 'post',
                    url: Update_PickUp_Outlet,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${token}`
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        setNearestOutlet({
                            city: n_form_new[0].value,
                            id: n_form_new[1].value,
                            name: n_form_new[2].value,
                            outletType: n_form_new[3].value,
                            masterName: n_form_new[4].value
                        });
                        setNearestOutletParams({
                            city: n_form_new[0].value,
                            id: n_form_new[1].value,
                            name: n_form_new[2].value,
                            outletType: n_form_new[3].value,
                            masterName: n_form_new[4].value
                        });
                        toast.success(response.data.msg);
                        setUserData(response.data.data);
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                let n_form = $(form).serializeArray();
                console.log(n_form)
                localStorage.setItem('pickupDinein', JSON.stringify(n_form));
                localStorage.setItem('location', JSON.stringify({
                    city: n_form[0].value,
                    id: n_form[1].value,
                    name: n_form[2].value,
                    outletType: n_form[3].value,
                    masterName: n_form[4].value,
                }));
                setNearestOutlet({
                    city: n_form[0].value,
                    id: n_form[1].value,
                    name: n_form[2].value,
                    outletType: n_form[3].value,
                    masterName: n_form[4].value,
                });
                setNearestOutletParams({
                    city: n_form[0].value,
                    id: n_form[1].value,
                    name: n_form[2].value,
                    outletType: n_form[3].value,
                    masterName: n_form[4].value,
                });
                toast.success('Location selected');
                window.location.reload();
            }
        }
    });
    const resendOtp = () => {
        var config = {
            method: 'post',
            url: Login_User_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: { mobile_number: Mobile_Number },
        };
        axios(config)
            .then(async (response) => {
                if (response.data.error == false) {
                    toast.success(response.data.msg);
                    $("#exampleModalToggle .loginClose").click();
                    window.$('#exampleModalToggle2').offcanvas('show');
                    const apiUrl = `https://enterprise.smsgupshup.com/GatewayAPI/rest?method=SendMessage&send_to=${response.data.user.mobile_number}&msg=Your%20OTP%20for%20Wok%20On%20Fire%20Login%20is%20${response.data.user.otp}.%20OTP%20is%20confidential%3B%20please%20enter%20it%20yourself%20%26%20do%20not%20disclose%20it%20to%20anyone.&msg_type=TEXT&userid=2000232741&auth_scheme=plain&password=Anjusateesh@1906&v=1.1&format=text`;
                    // Send the SMS using Axios
                    await axios.get(apiUrl)
                        .then(response => {
                            console.log('SMS sent successfully:', response.data);
                        })
                        .catch(error => {
                            console.error('Error sending SMS:', error);
                        });
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const LimithandleChange = (event) => {
        const value = event.target.value;
        const onlyDigits = /^\d*$/;
        if (onlyDigits.test(value) && value.length <= 10) {
            setMobile_Number(value);
        }
    };
    const DeliveryTypeHandleChange = (val) => {
        setDeliveryType(val);
        localStorage.setItem('orderType', val);
        if (token != null) {
            var config = {
                method: 'post',
                url: Change_Delivery_Type_Api,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    type: val
                }
            };
            axios(config)
                .then((response) => {
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    useEffect(() => {
        var config = {
            method: 'get',
            url: Get_AllCityOutlet_Api,
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    setCity(response.data.data.cityData);
                    setOutlets({ ...outlets, all: response.data.data.StoreData });
                    if (typeof userData.pickupOutletStore != typeof undefined) {
                        let cityOutlet = response.data.data.StoreData.filter(x => x.city.toLowerCase() == userData.pickupOutletStore.city.toLowerCase());
                        setOutlets({ ...outlets, selected: cityOutlet });
                        setArea(userData.pickupOutletStore)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    $('.otp-digit-group').find('input').each(function () {
        $(this).attr('maxlength', 1);
        $(this).on('keyup', function (e) {
            var parent = $($(this).parent());
            if (e.keyCode === 8 || e.keyCode === 37) {
                var prev = parent.find('input#' + $(this).data('previous'));
                $(this).attr('type', 'text');
                if (prev.length) {
                    $(prev).select();
                }
            } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                var next = parent.find('input#' + $(this).data('next'));
                $(this).attr('type', 'password');
                if (next.length) {
                    $(next).select();
                } else {
                    if (parent.data('autosubmit')) {
                        parent.submit();
                    }
                }
            }
        });
    });
    const setOutletByCity = (city) => {
        if (city == '') {
            setOutlets({ ...outlets, selected: [] });
            setArea([]);
            setUserData({ ...userData, pickupOutlet: '', city: '' })
        } else {
            let cityOutlet = outlets.all.filter(x => x.city.toLowerCase() == city);
            setOutlets({ ...outlets, selected: cityOutlet });
            setArea(cityOutlet[0])
            setUserData({ ...userData, pickupOutlet: cityOutlet[0]._id, city: city })
        }
    }
    const DeliveryOutletSearch = (e) => {
        e.preventDefault();
        let Search = e.target.value;
        if (Search == '' || Search == null || Search == undefined) {
            console.log('Please enter area / city');

        } else {
            var config = {
                method: 'post',
                url: Outlet_Search_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: { search: Search },
            };
            axios(config)
                .then(async (response) => {
                    if (response.data.error == false) {
                        let result = response.data.data;
                        for (let element of result) {
                            element.masterName = element.masterName ? element.masterName : element.name;
                        }
                        setLocationSuggestionData(result);
                    } else {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const deleteLocalStorageSearch = (id) => {
        let data = JSON.parse(localStorage.getItem('recent_location'));
        data = data.filter(x => x.id != id);
        localStorage.setItem('recent_location', JSON.stringify(data));
        setRecent_location(data);
    }
    const changeDeliveryOutlet = (item) => {
        console.log(item)
        setNearestOutlet({
            name: item.name,
            city: item.city,
            id: item._id,
            outletType: item.outletType,
            masterName: item.masterName,
        });
        setNearestOutletParams({
            name: item.name,
            city: item.city,
            id: item._id,
            outletType: item.outletType,
            masterName: item.masterName,
        });
        localStorage.setItem('location', JSON.stringify({
            name: item.name,
            city: item.city,
            id: item._id,
            outletType: item.outletType,
            masterName: item.masterName,
        }));

        toast.success('Location selected');

        if (token != null) {
            var config = {
                method: 'post',
                url: Update_PickUp_Outlet,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    area: typeof item.id == typeof undefined ? item._id : item.id,
                    pickUp: 0
                },
            };
            axios(config)
                .then((response) => {
                    setUserData(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        let Latest_Data = [{
            masterName: item.masterName,
            name: item.name || item.masterName,
            city: item.city,
            id: item._id,
            outletType: item.outletType
        }];
        if (Recent_location.findIndex(x => x.name == Latest_Data.name) < 0) {
            setRecent_location([...Recent_location, Latest_Data]);
            localStorage.setItem('recent_location', JSON.stringify(Recent_location));
        } else {
            setRecent_location(Latest_Data);
            localStorage.setItem('recent_location', JSON.stringify(Latest_Data));
        }
        // localStorage.setItem('recent_location', JSON.stringify(Recent_location));
        window.location.reload();
    }
    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            {/* <form id="nonseamless" className='d-none' method="post" name="redirect" ref={formRef} action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> */}
            <form id="nonseamless" className='d-none' method="post" name="redirect" ref={formRef} action=" https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                <input id="encRequest" name="encRequest" readOnly /><input name="access_code" id="access_code" readOnly />
            </form>
            {(fileName == 'homePage') ?
                <header id="header" className="main-header">
                    <div className="container">
                        <div className="menu-container">
                            <div className="heading row justify-content-between align-items-center">
                                <div className="row align-items-center">
                                    <div className="radio-btn">
                                        <input type="radio" id="specifyColor" name="Delivery" onChange={() => DeliveryTypeHandleChange('delivery')} checked={DeliveryType == 'delivery' ? true : false} />
                                        <label htmlFor="specifyColor">Delivery</label>
                                        <input type="radio" id="pickUp" name="Delivery" onChange={() => DeliveryTypeHandleChange('take_out')} checked={DeliveryType == 'take_out' ? true : false} />
                                        <label htmlFor="pickUp">Pick up | Dine-in</label>
                                    </div>
                                    <div className="add" data-bs-target={`#${DeliveryType == 'delivery' ? 'locationModal1' : 'locationModal'} `} data-bs-toggle="offcanvas" style={{ cursor: "pointer" }}>
                                        <img src="../../../assets/images/location.png" />
                                        <p id='nerestLocation'> {!isEmptyObject(nearestOutlet) ? nearestOutlet.masterName ? nearestOutlet.masterName : nearestOutlet.name : 'Moti Begumwadi, Nanpura, Surat'} </p>
                                        <img src="../../../assets/images/edit.png" />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'>
                                        <Link to={"/events"}>
                                            <div className='position-relative text-black mb-3 mb-md-0' style={{ fontWeight: 600 }}>
                                                Media/Events
                                            </div>
                                        </Link>
                                    </div>
                                    {token != null ?
                                        <div className="profile" data-bs-target="#exampleModal" data-bs-toggle="offcanvas">
                                            <a>
                                                <h4>{userName ? userName : 'User'}</h4>
                                                <p>My Account</p>
                                            </a>
                                        </div>
                                        :
                                        <div>
                                            <button type="button" style={{ fontWeight: '600' }}>
                                                <a data-bs-target="#loginModal" data-bs-toggle="offcanvas">Login</a> / <a data-bs-target="#signupModal" data-bs-toggle="offcanvas">signUp</a>
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <nav className="navbar navbar-expand-md navbar-dark">
                                <div className="container">
                                    <a className="navbar-brand" href="/">
                                        <img src={logo} />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarsExample05">
                                        <ul className="navbar-nav m-auto pl-0">
                                            <li className="nav-item">
                                                {
                                                    typeof nearestOutlet.outletType != typeof undefined && nearestOutlet.outletType.toLowerCase().includes("express") ?
                                                        <a className={`nav-link my-2 my-xl-0 `} onClick={() => navigate(`/product`)}>INDO CHINESE - EXPRESS</a> :
                                                        <a className={`nav-link my-2 my-xl-0 `} onClick={() => navigate(`/product`)}>INDO CHINESE</a>
                                                }
                                            </li>
                                            {/* <li className="nav-item">
                                                {
                                                    typeof nearestOutlet.outletType != typeof undefined && nearestOutlet.outletType.includes("express") &&
                                                    <a className={`nav-link my-2 my-xl-0 `} onClick={() => navigate(`/product`)}>INDO CHINESE - EXPRESS</a>
                                                }
                                                <a className={`nav-link my-2 my-xl-0 ${typeof nearestOutlet.outletType != typeof undefined ? nearestOutlet.outletType.toLowerCase() != 'indo chinese express' ? 'd-none' : '' : ''} `} onClick={() => navigate(`/product`)}>INDO CHINESE - EXPRESS</a>
                                            </li> */}
                                            {/* <li className="nav-item">
                                                <button type="button" className="nav-link order my-2 my-xl-0" onClick={() => navigate(`/product`)}>ORDER ONLINE</button>
                                            </li> */}
                                        </ul>
                                        <ul className="navbar-nav my-2 my-xl-0">
                                            {/* <li className="nav-item cta-btn">
                                                <a href="/">
                                                    <img src={logo01} />
                                                </a>
                                            </li> */}
                                            <li className="nav-item d-flex align-items-center" style={{ width: "30px", height: "30px" }}>
                                                <a href="/cart">
                                                    <div className='position-relative cart-icon-conatiner'>
                                                        <img src='../../../assets/images/cart.png' />
                                                        <p className='cart_count'>
                                                            {CartCount}
                                                        </p>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                : (fileName == 'product') ?
                    <header id="header" className="main-header">
                        <div className="container">
                            <div className="menu-container">
                                <div className="heading row justify-content-between align-items-center">
                                    <div className="row align-items-center">
                                        <div className="radio-btn">
                                            <input type="radio" id="productSpecifyColor" name="productDelivery" onChange={() => DeliveryTypeHandleChange('delivery')} checked={DeliveryType == 'delivery' ? true : false} />
                                            <label htmlFor="productSpecifyColor">Delivery</label>
                                            <input type="radio" id="productPickUp" name="productDelivery" onChange={() => DeliveryTypeHandleChange('take_out')} checked={DeliveryType == 'take_out' ? true : false} />
                                            <label htmlFor="productPickUp">Pick up | Dine-in</label>
                                        </div>
                                        <div className="add" data-bs-target={`#${DeliveryType == 'delivery' ? 'locationModal1' : 'locationModal'} `} data-bs-toggle="offcanvas" style={{ cursor: "pointer" }}>
                                            <img src="../../../assets/images/location.png" />
                                            <p> {!isEmptyObject(nearestOutlet) ? nearestOutlet.name : 'Moti Begumwadi, Nanpura, Surat'} </p>
                                            <img src="../../../assets/images/edit.png" />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-3'>
                                            <Link to={"/events"}>
                                                <div className='position-relative text-black mb-3 mb-md-0' style={{ fontWeight: 600 }}>
                                                    Media/Events
                                                </div>
                                            </Link>
                                        </div>
                                        {token != null ?
                                            <div className="profile" data-bs-target="#exampleModal" data-bs-toggle="offcanvas">
                                                <a>
                                                    <h4>{userName ? userName : 'User'}</h4>
                                                    <p>My Account</p>
                                                </a>
                                            </div>
                                            :
                                            <div>
                                                <button type="button" style={{ fontWeight: '600' }}>
                                                    <a data-bs-target="#loginModal" data-bs-toggle="offcanvas">Login</a> / <a data-bs-target="#signupModal" data-bs-toggle="offcanvas">signUp</a>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <nav className="navbar navbar-expand-md navbar-dark">
                                    <div className="container">
                                        <a className="navbar-brand" href="/">
                                            <img src="../../../assets/images/logo.png" />
                                        </a>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarsExample05">
                                            <ul className="navbar-nav m-auto pl-0">
                                                <li className="nav-item">
                                                    <a className={`nav-link ${typeof nearestOutlet.outletType != typeof undefined ? nearestOutlet.outletType.toLowerCase() != 'indo chinese' ? 'd-none' : '' : ''} `} onClick={() => navigate(`/product`)}>INDO CHINESE</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className={`nav-link ${typeof nearestOutlet.outletType != typeof undefined ? nearestOutlet.outletType.toLowerCase() != 'indo chinese express' ? 'd-none' : '' : ''} `} onClick={() => navigate(`/product`)}>INDO CHINESE - EXPRESS</a>
                                                </li>
                                            </ul>
                                            <ul className="navbar-nav">
                                                <li className="nav-item me-3">
                                                    <input type="text" onChange={(e) => { setSearchTextParams(e.target.value); }} name='Search' placeholder="Search" />
                                                    <img className='serch-btn' src="/assets/images/serch-icon.png" />
                                                </li>
                                                <li className="nav-item d-flex align-items-center" style={{ width: "30px", height: "30px" }}>
                                                    <a href="/cart">
                                                        <div className='position-relative cart-icon-conatiner'>
                                                            <img src='../../../assets/images/cart.png' />
                                                            <p className='cart_count'>
                                                                {cartCount}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </header>
                    :
                    <header id="header" className="main-header profile">
                        <div className="container">
                            <div className="menu-container">
                                <nav className="navbar m-auto navbar-expand-md navbar-dark">
                                    <div className="container">
                                        <a className="navbar-brand" href="/">
                                            <img src="../../../assets/images/logo.png" />
                                        </a>
                                        <div className="nav navbar-nav navbar-right" id="navbarsExample05">
                                            <div className="account">
                                                <ul className="navbar-nav pe-3 flex-row">
                                                    <li className="nav-item d-flex align-items-center">
                                                        <Link to={"/events"}>
                                                            <div className='position-relative me-3'>
                                                                <p className='text-black' style={{ fontWeight: 600 }}>Media/Events</p>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item d-flex align-items-center" style={{ width: "30px", height: "30px" }}>
                                                        <a href="/cart">
                                                            <div className='position-relative cart-icon-conatiner'>
                                                                <img src='../../../assets/images/cart.png' />
                                                                <p className='cart_count'>
                                                                    {typeof cartCount != typeof undefined ? cartCount : CartCount}
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                                {token != null ?
                                                    <div className="profile d-flex" data-bs-target="#exampleModal" data-bs-toggle="offcanvas">

                                                        <img src="../../../assets/images/profile.png" />
                                                        <a>
                                                            <h4 style={{ fontSize: "14px", color: "#000", fontWeight: "600" }}>{userName ? userName : 'User'}</h4>
                                                            <p style={{ fontSize: "12px", color: "#000" }}>My Account</p>
                                                        </a>
                                                    </div>
                                                    :
                                                    <div>
                                                        <button type="button" style={{ fontWeight: '600' }}>
                                                            <a data-bs-target="#loginModal" data-bs-toggle="offcanvas">Login</a> / <a data-bs-target="#signupModal" data-bs-toggle="offcanvas">signUp</a>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </header>
            }

            <div className="payment-modal offcanvas offcanvas-end" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>Wok on fire</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img
                            src="../../../assets/images/close.png" /></button>
                    </div>
                    <h6>Wallet Balance</h6>
                    <div className="wallet">
                        <div className="balance">
                            <img src="../../../assets/images/wallet-icon.png" />
                            <h4>₹ {userData.wallet}</h4>
                        </div>
                        <button className="add" data-bs-toggle="offcanvas" data-bs-target="#addWallet">+ ADD</button>
                    </div>
                    <div className="ProfileSidenavList">
                        <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/pro"); }}>Profile</a></p>
                        <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/order"); }}>Order</a></p>
                        <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/wallet"); }}>Wallet</a></p>
                        <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/add"); }}>Address</a></p>
                        <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/fav"); }}>Favorite</a></p>
                        <p onClick={(e) => {
                            e.preventDefault();
                            LogOutFunction();
                        }}>Sign Out</p>
                    </div>
                </div>
            </div>

            <div className="offcanvas offcanvas-end" tabIndex="-1" id="loginModal" aria-labelledby="loginModalLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <img src="../../../assets/images/dummy.png" />
                    <h4>Login</h4>
                    <h6>or<a className="create" data-bs-toggle="offcanvas" data-bs-target="#signupModal"> Create an account</a></h6>
                    <p>Login with your valid mobile number</p>
                    <form ref={loginFormRef} id="loginForm">
                        <div className="input-label-group mb-3">
                            <label>Mobile Number</label>
                            <input className="input-field m-0 newerror" type="text" name='mobile_number' onChange={LimithandleChange} value={Mobile_Number} />
                        </div>
                        <button type='submit' className="otp-btn">SEND OTP</button>
                    </form>
                    <h5>By Continuing you agree to the <a className="terms"> Terms of Services</a> and  <a className="terms">Privacy policy</a></h5>
                </div>
            </div>

            <div className="otp offcanvas offcanvas-end" id="otpModal" role="dialog" aria-labelledby="otpModalLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <img src="../../../assets/images/dummy.png" />
                    <h4>Verify OTP</h4>
                    <p>Enter 6 digit verification code sent by SMS {userNumber}</p>
                    <form ref={otpFormRef} id="otpForm">
                        <div className='otp-digit-group' data-group-name="digits">
                            <input className="input-field otp-get-data" type="text" id="digit-1" name="digit-1" data-next="digit-2" autoComplete="off" />
                            <input className="input-field otp-get-data" type="text" id="digit-2" name="digit-2" data-previous="digit-1" data-next="digit-3" autoComplete="off" />
                            <input className="input-field otp-get-data" type="text" id="digit-3" name="digit-3" data-previous="digit-2" data-next="digit-4" autoComplete="off" />
                            <input className="input-field otp-get-data" type="text" id="digit-4" name="digit-4" data-previous="digit-3" data-next="digit-5" autoComplete="off" />
                            <input className="input-field otp-get-data" type="text" id="digit-5" name="digit-5" data-previous="digit-4" data-next="digit-6" autoComplete="off" />
                            <input className="input-field otp-get-data" type="text" id="digit-6" name="digit-6" data-previous="digit-5" autoComplete="off" />
                        </div>
                        <button type='button' style={{ cursor: "pointor" }} onClick={resendOtp}>
                            <h6>Resend OTP</h6>
                        </button>
                        <button className="otp-btn" type='submit'>Verify & Continue</button>
                    </form>
                </div>
            </div>

            <div className="offcanvas offcanvas-end" id="signupModal" role="dialog" aria-labelledby="signupModalLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div>
                        <img src="../../../assets/images/dummy.png" />
                        <h4>Sign Up</h4>
                        <h6>or<a className="create" data-bs-toggle="offcanvas" data-bs-target="#loginModal">  Login to your Account</a></h6>
                        <p>Fill your Correct Details</p>
                    </div>
                    <div>
                        <form ref={signUpFormRef} id="signUpForm">
                            <div className="input-label-group mb-3">
                                <label>Name</label>
                                <input className="input-field newerror mb-0" type="text" name='first_name' />
                            </div>
                            <div className="input-label-group mb-3">
                                <label>Mobile Number</label>
                                <input className="input-field newerror mb-0" type="text" name='mobile_number' />
                            </div>
                            <div className="input-label-group mb-3">
                                <label>Email Id</label>
                                <input className="input-field newerror mb-0" type="text" name='email' />
                            </div>
                            <button type='submit' className="otp-btn">Sign Up</button>
                        </form>
                    </div>
                    <h5>By Continuing you agree to the <a className="terms"> Terms of Services</a> and  <a className="terms">Privacy policy</a></h5>
                </div>
            </div>

            <div className="offcanvas offcanvas-end" id="locationModal" role="dialog" aria-labelledby="locationModalLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>Select your Location</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <div className="radio-btn">
                        <input type="radio" id="modelSpecifyColor" name="pickUp" onChange={() => DeliveryTypeHandleChange('delivery')} checked={DeliveryType == 'delivery' ? true : false} data-bs-target="#locationModal1" data-bs-toggle="offcanvas" style={{ cursor: "pointer" }} />
                        <label htmlFor="modelSpecifyColor">Delivery</label>
                        <input type="radio" id="modelPickUp" name="pickUp" onChange={() => DeliveryTypeHandleChange('take_out')} checked={DeliveryType == 'take_out' ? true : false} />
                        <label htmlFor="modelPickUp">Pick up | Dine-in</label>
                    </div>
                    <p>Which store would you like to Pickup/Dine-in order from</p>
                    <form ref={pickUpLocationFormref} id='pickUpLocation'>
                        <div className="select-city">
                            <select className="city newerror" name='city' value={userData.city} onChange={(e) => setOutletByCity(e.target.value)}>
                                <option value=''>Select City</option>
                                {
                                    city.length > 0 ? city.map((item, index) => {
                                        return (
                                            <option key={index} value={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</option>
                                        )
                                    }) : ''
                                }
                            </select>
                            <div className="add">
                                <img src="../../../assets/images/add-serch.png" />
                            </div>
                        </div>
                        <div className="select-area">
                            <select className="area newerror" name='area' value={userData.pickupOutlet} onChange={(e) => {
                                setUserData({ ...userData, pickupOutlet: e.target.value });
                                setArea(outlets.all.filter(x => x._id == e.target.value)[0])
                            }}>
                                <option value=''>Select Area</option>
                                {outlets && outlets.selected.length > 0 ? outlets.selected.map((item, index) => {
                                    return (
                                        <option key={index} value={item._id} onClick={(item) => localStorage.setItem('location', item)}>{item.masterName ? item.masterName : item.name}</option>
                                    )
                                }) : outlets.all.length > 0 ? outlets.all.map((item, index) => {
                                    return (
                                        <option key={index} value={item._id}>{item.masterName ? item.masterName : item.name}</option>
                                    )
                                }) : ''
                                }
                            </select>
                            <div className="sug">
                                <img src="../../../assets/images/serch.png" />
                            </div>
                        </div>
                        {
                            !isEmptyObject(area) ?
                                <>
                                    <h6>Pickup/Dine-in Your Order from Restaurant at</h6>
                                    <div className="add-box">
                                        <img src="../../../assets/images/serch.png" />
                                        <div className="txt">
                                            <h5>{area.city.toLowerCase().charAt(0).toUpperCase() + area.city.toLowerCase().slice(1)}</h5>
                                            <p>{area.masterName ? area.masterName : area.name}</p>
                                            <input type="hidden" name='name' value={area.masterName ? area.masterName : area.name} />
                                            <input type="hidden" name='outletType' value={area.outletType} />
                                            <input type="hidden" name='masterName' value={area.masterName ? area.masterName : area.name} />
                                        </div>
                                    </div>
                                </> : ''
                        }
                        <button type='submit' className="pro-btn">PROCEED</button>
                    </form>
                </div>
            </div>

            <div className="offcanvas offcanvas-end" id="locationModal1" role="dialog" aria-labelledby="locationModal1Label" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>Select your Location</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <div className="radio-btn">
                        <input type="radio" id="modelLSpecifyColor" name="modelDelivery" onChange={() => DeliveryTypeHandleChange('delivery')} checked={DeliveryType == 'delivery' ? true : false} />
                        <label htmlFor="modelLSpecifyColor">Delivery</label>
                        <input type="radio" id="modelLPickUp" name="modelDelivery" onChange={() => DeliveryTypeHandleChange('take_out')} checked={DeliveryType == 'take_out' ? true : false} data-bs-target="#locationModal" data-bs-toggle="offcanvas" style={{ cursor: "pointer" }} />
                        <label htmlFor="modelLPickUp">Pick up | Dine-in</label>
                    </div>
                    <p>We will deliver right to your door</p>
                    <div className='position-relative'>
                        <input className="serch" type="text" placeholder="Search Area/Locality" onChange={(e) => { DeliveryOutletSearch(e) }} />
                        <button className="locate" onClick={() => {
                            localStorage.removeItem('location');
                            window.location.reload();
                        }}>Locate me</button>
                    </div>
                    <div className="box">
                        <h4>{LocationSuggestionData[0] ? 'SUGGESTIONS' : ''}</h4>
                        {LocationSuggestionData.map((item, index) => {
                            return (
                                <div className="area" style={{ cursor: "pointer" }} key={index} onClick={() => changeDeliveryOutlet(item)}>
                                    <img src="../../../assets/images/add-serch.png" />
                                    <div className="area-name">
                                        <h5>{item.city}</h5>
                                        <p>{item.masterName ? item.masterName : item.name}</p>
                                    </div>
                                </div>
                            )
                        })}
                        {/* <div className="area">
                            <img src="../../../assets/images/add-serch.png" />
                            <div className="area-name">
                                <h5>Vapi Station</h5>
                                <p>Vapi, Gujarat, India</p>
                            </div>
                        </div>
                        <div className="area">
                            <img src="../../../assets/images/add-serch.png" />
                            <div className="area-name">
                                <h5>Vapi GIDC</h5>
                                <p>Vapi, Gujarat, India</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="box">
                        <h4>RECENT SEARCH</h4>
                        {Recent_location.length > 0 ? Recent_location.map((item, index) => {
                            return (
                                <div className="recent" key={index}>
                                    <div className="old-serch" style={{ cursor: 'pointer' }} onClick={() => changeDeliveryOutlet(item)}>
                                        <img src="../../assets/images/location.png" />
                                        <div className="area-name">
                                            <h5>{item.city}</h5>
                                            <p>{item.masterName ? item.masterName : item.name}</p>
                                        </div>
                                    </div>
                                    <img src="../../assets/images/delete.png" style={{ cursor: "pointer" }} onClick={(e) => deleteLocalStorageSearch(item.id)} />
                                </div>
                            )
                        }) : ''}
                    </div>
                </div>
            </div>

            <div className="payment-modal offcanvas offcanvas-end" id="addWallet" tabIndex="-1" role="dialog" aria-labelledby="addWalletLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>Add Wallet</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <form ref={addWalletRef} id='addWalletForm'>
                        <div className='mb-2'>
                            <input type="number" className="input-tag-design newerror mb-0" name='amount' id='amount' placeholder="Enter Amount" />
                        </div>
                        <button type='submit' className="add-money">ADD MONEY</button>
                    </form>
                </div>
            </div>

            <div className="payment-modal offcanvas offcanvas-end" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="exampleModal2Label" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>My Profile</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <input type="text" placeholder="Name" />
                    <span></span>
                    <input type="text" placeholder="Mobile No." />
                    <input type="text" placeholder="Email" />
                    <button className="add-money">SAVE & CONTINUE</button>
                </div>
            </div>

            <div className="profile-data offcanvas offcanvas-end" id="exampleModalProfile" tabIndex="-1" role="dialog" aria-labelledby="exampleModalProfileLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>My Profile</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <div className="input-box name">
                        <div className="box">
                            <img src="../../../assets/images/man.png" />
                        </div>
                        <input type="text" placeholder="Enter Name" />
                    </div>
                    <div className="input-box number">
                        <div className="box">
                            +91
                        </div>
                        <input type="number" placeholder="Mobile No." />
                    </div>
                    <div className="input-box mail">
                        <div className="box">
                            <img src="../../../assets/images/mail.png" />
                        </div>
                        <input type="text" placeholder="Email" />
                    </div>
                    <button className="save-btn">SAVE & CONTINUE</button>
                </div>
            </div>

            <div className="Search-modal offcanvas offcanvas-end" id="SearchModal" tabIndex="-1" role="dialog" aria-labelledby="SearchLabel" aria-hidden="true">
                <div className="offcanvas-body p-5">
                    <div className="topic">
                        <h2>Add Wallet</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../../assets/images/close.png" /></button>
                    </div>
                    <form id='addWalletForm'>
                        <div className='mb-2'>
                            <input type="number" className="input-tag-design newerror mb-0" name='amount' id='amount' placeholder="Enter Amount" />
                        </div>
                        <button type='submit' className="add-money">ADD MONEY</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Header