import React, { useEffect, useState } from 'react'
import Header from '../UI/Header/Header'
import { useNavigate } from 'react-router';
import { Get_Login_User_Api, Last_Order_Api } from '../../api';
import axios from 'axios';
import moment from 'moment';
import { isEmptyObject } from 'jquery';

export default function PaymentFailed() {
    const navigate = useNavigate();
    const [order, setOrder] = useState({});
    const [userData, setUserData] = useState({});
    const token = localStorage.getItem('authToken');
    useEffect(() => {
        var config = {
            method: 'get',
            url: Get_Login_User_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    setUserData(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (userData.id) {
            axios({
                method: 'get',
                url: Last_Order_Api + userData.id,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {
                let data = response.data.data
                if (data.length > 0) {
                    setOrder(data[0]);
                }
            })
        }
    }, [userData]);
  return (
    <>
        <Header fileName={'paymentFailed'} />
        { !isEmptyObject(order) &&
            <div className="payment">
                <div  className="container">
                    <div className="sucess-box bg-danger">
                        <img src="../../assets/images/drop.png" />
                        <p>Your Transaction {order.order_status}</p>
                    </div>
                    <div className="payment-detail-box">
                        <table style={{width : "100%"}}>
                            <tbody>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Order Date & Time</th>
                                    <th>Type</th>
                                </tr>
                                <tr>
                                    <td>{order.source.order_id}</td>
                                    <td>{moment(order.createdAt).format('DD MMM yyyy, hh:mm a')}</td>
                                    <td>Deliverd To</td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>{order.customer.address1 + ', ' + order.customer.address2 + ', ' + order.customer.city + ',  (' + order.customer.mobile + ')'}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>Order</th>
                                    <td>
                                        { order.products.length > 0 &&
                                            order.products.map((x, index) => {
                                                return (
                                                    <p key={index}>{index+1}. {x.name}</p>
                                                )
                                            })
                                        }
                                    </td>
                                    <td>
                                        { order.items.length > 0 &&
                                            order.items.map((x, index) => {
                                                return (
                                                    <p key={index}>₹ {(x.price * x.quantity).toFixed(2)}</p>
                                                )
                                            })
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>CHARGES</th>
                                    <td>Wok On fire Charges</td>
                                    <td>₹ { order.tax + order.deliveryCharge }</td>
                                </tr>
                                {
                                    order.discount_value != 0 &&
                                    <tr>
                                        <th>DISCOUNT</th>
                                        <td>Wok On Fire Discount</td>
                                        <td>₹ {order.discount_value.toFixed(2)}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>PAYMENT</th>
                                    <td>{ order.payments.type == 'COD' ? 'Cash On Delivery' : 'Online' } <br />{order.payments.type}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>TOTAL</th>
                                    <td></td>
                                    <td>{order.amount.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <button className="re-order-btn" onClick={() => navigate('/cart')}>DO ANOTHER ORDER</button>
                    </div>
                </div>
            </div>
        }
    </>
  )
}
